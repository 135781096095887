exports.components = {
  "component---posts-post-1-index-mdx": () => import("./../../../posts/post-1/index.mdx" /* webpackChunkName: "component---posts-post-1-index-mdx" */),
  "component---posts-post-2-index-mdx": () => import("./../../../posts/post-2/index.mdx" /* webpackChunkName: "component---posts-post-2-index-mdx" */),
  "component---posts-post-3-index-mdx": () => import("./../../../posts/post-3/index.mdx" /* webpackChunkName: "component---posts-post-3-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-beliefs-tsx": () => import("./../../../src/pages/beliefs.tsx" /* webpackChunkName: "component---src-pages-beliefs-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

